import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { withStyles } from "@material-ui/styles"

import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"

import searchIcon from "../images/search-icon.webp"
import arrow from "../images/select-arrow.webp"

import ChevronRight from "@material-ui/icons/ChevronRight"

import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock"
import { CoreParagraphBlock } from "../components/blocks/CoreParagraphBlock"
import CookiesConsent from "../components/CookiesConsent"

const styles = {
  searchTitle: {
    backgroundColor: "#F1ECE8",
    padding: "12px 0 32px 0",
    marginBottom: 32,
    "& .MuiTypography-root": {
      marginBottom: 12,
    },
    "& p": {
      marginBottom: 0,
      fontSize: 16,
      lineHeight: 2,
    },
  },
  stockistBlock: {
    width: "calc(100% - 24px)",
    margin: "0 12px 50px",
    backgroundColor: "#E4DFD9",
    padding: 32,
    "& p": {
      display: "block",
      textAlign: "center",
    },
    "& .search-form": {
      width: "100%",
      margin: "0 auto",
      maxWidth: 550,
      position: "relative",
      "& label": {
        display: "block",
        fontFamily: '"Oxygen", sans-serif',
        fontWeight: "bold",
        fontSize: 12,
        letterSpacing: "0.152em",
        lineHeight: 1.33,
        color: "#4B3048",
        textTransform: "uppercase",
        marginBottom: 5,
      },
      "& input": {
        borderRadius: 25,
        padding: "15px 30px",
        fontSize: 14,
        lineHeight: 1,
        fontFamily: '"Oxygen", sans-serif',
        letterSpacing: "0.016em",
        backgroundColor: "#fff",
        border: "none",
        width: "100%",
        height: 50,
        paddingRight: 76,
      },
      "& button": {
        bakgroundColor: "#B3D57C",
        border: "none",
        borderRadius: "50%",
        fontSize: 0,
        backgroundImage: "url(" + searchIcon + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: 46,
        height: 46,
        position: "absolute",
        bottom: 2,
        right: 2,
      },
    },
  },
  results: {
    width: "calc(100% - 24px)",
    margin: "0 auto 32px",
    "& .result-nav": {
      position: "relative",
      marginBottom: 30,
      "@media (min-width: 960px)": {
        marginTop: 50,
        borderBottom: "none",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .count": {
        fontSize: 16,
        letterSpacing: "0.016em",
        lineHeight: 2,
        fontWeight: "normal",
        color: "#00313C",
        fontFamily: '"Oxygen", sans-serif',
      },
      "& .sort-wrap": {
        backgroundColor: "#FCF9F5",
        marginTop: 12,
        transition: "opacity .3s ease-in-out",
        "@media (min-width: 960px)": {
          height: "auto !important",
          opacity: "1 !important",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          marginTop: 0,
        },
        "& .select-row": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "12px 12px 0",
          alignItems: "center",
          "@media (min-width: 960px)": {
            padding: 12,
          },
          "& label": {
            fontSize: 12,
            fontFamily: '"Oxygen", sans-serif',
            color: "#00313C",
            letterSpacing: "0.152em",
            lineHeight: "32px",
            textTransform: "uppercase",
            fontWeight: "bold",
            marginRight: 12,
          },
          "& select": {
            borderRadius: 4,
            cursor: "pointer",
            color: "#454545",
            border: "1px solid #ACA39A",
            fontSize: 16,
            backgroundColor: "white",
            letterSpacing: "0.016em",
            padding: "17px 20px",
            lineHeight: 1.1,
            fontFamily: '"Oxygen", sans-serif',
            appearance: "none",
            outline: "none",
            backgroundImage: "url(" + arrow + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: 14,
            backgroundPosition: "right 15px top calc(50% - 1px)",
            paddingRight: 50,
            "@media (min-width: 960px)": {
              minWidth: 0,
            },
          },
          "&.show-count": {
            paddingBottom: 12,
          },
        },
      },
    },
    "& .results-grid": {
      position: "relative",
      marginBottom: 32,
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      width: "calc(100% + 24px)",
      margin: -12,
      "& .result": {
        padding: 12,
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        "@media (min-width: 600px)": {
          maxWidth: "50%",
          flexBasis: "50%",
        },
        "@media (min-width: 1280px)": {
          flex: "0 0 25%",
        },
        "& img": {
          display: "block",
          overflow: "hidden",
          position: "relative",
          borderRadius: 8,
          marginBottom: 15,
        },
        "& h4": {
          color: "#4B3048",
          width: "calc(100% - 100px)",
          height: "48px",
          display: "block",
          overflow: "hidden",
          fontSize: "16px",
          fontFamily: '"Butler", serif',
          lineHeight: "24px",
          marginBottom: "8px",
          letterSpacing: "0.008em",
        },
        "& a": {
          display: "block",
          padding: 15,
          borderTop: "1px solid #ACA39A",
          borderBottom: "1px solid #ACA39A",
          color: "#00313C",
          fontFamily: '"Oxygen", sans-serif',
          fontWeight: "bold",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontSize: 16,
          textAlign: "center",
          "& svg": {
            display: "inline-block",
            verticalAlign: "bottom",
          },
        },
      },
    },
  },
}

class FindRetailer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      results: undefined,
      searchName: "",
    }

    this.searchFile = this.searchFile.bind(this)
  }

  searchFile(e) {
    e.preventDefault()

    const results = this.props.data.allWpProduct.edges
      .filter(product => {
        if (
          product?.node?.title
            ?.toLowerCase()
            .includes(this?.state?.searchName?.toLowerCase())
        )
          return true

        if (
          product.node.ProductData.variants
            .map(variant => variant.sku)
            .includes(this.state.searchName)
        ) {
          return true
        }
        return false
      })
      .filter(product => {
        if (product.node.ProductMeta.pdfs) {
          return true
        }

        if (product.node.ProductMeta.videos) {
          return true
        }

        return false
      })

    this.setState({
      results,
    })
  }

  sortProducts() {
    const { results, sort } = this.state

    if (sort === "name") {
      return results.sort((a, b) => {
        return b.node.title.localeCompare(a.node.title)
      })
    }

    return results
  }

  render() {
    const { classes } = this.props
    const { results } = this.state

    return (
      <>
        <div className={classes.searchTitle}>
          <div className="wrapper">
            <CoreHeadingBlock
              attributes={{
                textAlign: "center",
                anchor: "",
                className: "",
                content: "Assembly & Installation",
                level: 1,
                textColor: "initial",
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
            <CoreParagraphBlock
              attributes={{
                align: "center",
                className: "post-excerpt",
                content:
                  "Every Zest item that isn't already pre-built comes with an assembly guide. Many also come with an animated assembly video that details exactly how to assemble your item. Search below by entering the product name or SKU.",
                textColor: "initial",
                backgroundColor: "",
                fontSize: "",
                __typename: "WpCoreParagraphBlockAttributes",
              }}
              innerBlocks={[]}
            />
          </div>
        </div>
        <div className="wrapper">
          <div className={classes.stockistBlock}>
            <form className="search-form" onSubmit={this.searchFile}>
              <label htmlFor="search">Search for assembly guides</label>
              <input
                name="search"
                placeholder="Product name or SKU"
                onChange={e => this.setState({ searchName: e.target.value })}
              />
              <button type="submit" aria-label="Search">
                Search
              </button>
            </form>
          </div>
          {results && results.length === 0 && (
            <div>
              <p>Sorry, we can't find any pages with your search term</p>
            </div>
          )}
          {results && results.length > 0 && (
            <div className={classes.results}>
              <div className="result-nav">
                {
                  <span className="count">
                    <strong>Products:</strong> {results.length} items
                  </span>
                }
              </div>
              <div className="results-grid">
                {results.map(result => {
                  return (
                    <div className="result" key={result.node.id}>
                      <img
                        src={result.node.ProductData.featuredImage}
                        alt=""
                        loading="lazy"
                      />
                      <h4>{result.node.title}</h4>
                      <Link to={`${result.node.uri}?tab=2`}>
                        View Assembly Guides <ChevronRight />
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <br />
        <br />
      </>
    )
  }
}

const Page = ({ data }) => {
  const StyledPage = withStyles(styles)(FindRetailer)

  return (
    <Layout
      meta={{
        metaTitle: "Find a Retailer | Zest Wooden Outdoor Products",
        metaDescription:
          "The Zest range of wooden garden structures and products are stocked by over 500 stores and garden centres across the UK, and many online retailers.",
      }}
      path="/assembly-search/"
      title="Account"
    >
      <Breadcrumb
        type="page"
        ancestors={null}
        current={{
          title: "Assembly & Installation",
          slug: "Assembly & Installation",
          uri: "/assembly-search/",
        }}
      />
      <StyledPage data={data} />
      <CookiesConsent></CookiesConsent>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProduct(limit: 500) {
      edges {
        node {
          id
          title
          uri
          ProductData {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
            featuredImage
            variants {
              sku
            }
          }
          ProductMeta {
            keywords
            pdfs {
              url
            }
            videos {
              url
            }
          }
        }
      }
    }
  }
`
export default Page
